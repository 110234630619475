let firebaseConfig = {
  apiKey: 'AIzaSyDtbZNDGHnKgpA8kvKcnXAcm0TKqU4sNL0',
  authDomain: 'auto-bechdel-test.firebaseapp.com',
  databaseURL: 'https://auto-bechdel-test.firebaseio.com',
  projectId: 'auto-bechdel-test',
  storageBucket: 'auto-bechdel-test.appspot.com',
  messagingSenderId: '794444103182',
  appId: '1:794444103182:web:67ade11df1255ddcfb465f',
  measurementId: 'G-QQVCCV9503',
};

firebase.initializeApp(firebaseConfig);

var bechdelTest = firebase.functions().httpsCallable('bechdelTest');

var submitButton = document.querySelector('.js-submit');
var randomizeButton = document.querySelector('.js-randomize');

var thisDoc = document;

var movieExamples = [
  {
    sample:
      "That's Pat Verona? The one who was gone for a year? I heard he was doing porn movies. I'm sure he's completely incapable of doing anything that interesting.",
    label: 'Female to female',
    movie: '10 Things I Hate About You',
  },
  {
    sample:
      "He's at a board meeting. Board meeting. Oh, that means hours, I suppose.",
    label: 'Female to female',
    movie: 'American Madness',
  },
  {
    sample:
      'You felt nothing? No spark? Two young people in Paris? Dangerous missions? Life and death? You mean, did I want a date?',
    label: 'Female to female',
    movie: 'The Bourne Supremacy',
  },
  {
    sample: 'You-betcha. My mom wanted me to have this?',
    label: 'Female to female',
    movie: 'Drop Dead Gorgeous',
  },
  {
    sample:
      "God I hope that's you and not your concussion talkin'. It's me... I just don't want this to be the thing you'd do over. This pageant's your ticket outta here. I know you can win, Amber.",
    label: 'Female to female',
    movie: 'Drop Dead Gorgeous',
  },
  {
    sample:
      'You came through, Nicky. I came through for you, Mom and the butterflies, Dad.',
    label: 'Male to male',
    movie: 'Little Nicky',
  },
  {
    sample:
      'Smart. Hard as hell to handicap. You know what I like? Hai Alai. Fast game. You know why I like it? Why?',
    label: 'Male to male',
    movie: 'Made',
  },
  {
    sample:
      "Mr. Deeds, does the name of Martin W. Semple mean anything to you? Not much. He's an uncle of mine, I think. I never saw him, but my mother's name was Semple, you know.",
    label: 'Male to male',
    movie: 'Mr. Deeds Goes To Town',
  },
  {
    sample: 'Do you think you can steer this thing? Sure.',
    label: 'Male to male',
    movie: 'The Talented Mr. Ripley',
  },
  {
    sample:
      'We probably ought to take his car, you know, teach him a lesson. Shut it, Teddy.',
    label: 'Male to male',
    movie: 'Memento',
  },
];

var clearResults = function () {
  thisDoc.querySelector('.js-test-results-quote').innerHTML = '';
  thisDoc.querySelector('.js-test-results-ffmm-movie').innerHTML = '';
  thisDoc.querySelector('.js-test-results').classList.remove('active');
};

var addRandomText = function (ev) {
  if (ev) {
    ev.preventDefault();
  }

  clearResults();

  var examples = [
    "What are you snickering about? I'm not snickering. I'm smiling. Because I'm happy. What am I missing? What do you have to be happy about?",
    'Come on. Crawl faster. DO you know how to get to the landing field from here? Sure. Go left.',
    'May the Force be with you.',
    "There's no place like home.",
    'Carpe diem. Seize the day, boys. Make your lives extraordinary.',
    "My mama always said life was like a box of chocolates. You never know what you're gonna get.",
    "There's no crying in baseball!",
    'Life is a banquet, and most poor suckers are starving to death!',
    'Magic Mirror on the wall, who is the fairest one of all?',
    "You don't understand! I coulda had class. I coulda been a contender. I could've been somebody, instead of a bum, which is what I am.",
    "They may take our lives, but they'll never take our freedom!",
    "Don't let somebody tell you, you can't do something, not even me. Alright?",
  ];

  thisDoc.querySelector('#content').value =
    movieExamples[Math.floor(Math.random() * movieExamples.length)].sample;
};

randomizeButton.addEventListener('click', addRandomText);

submitButton.addEventListener('click', (ev) => {
  ev.preventDefault();
  var text = thisDoc.querySelector('#content').value;
  thisDoc.querySelector('.input__form').classList.add('loading');
  thisDoc.querySelector('textarea').disabled = true;

  var samples = movieExamples.map((ex) => ex.sample);
  var partOfSample = samples.indexOf(text);

  bechdelTest({ content: text }).then(function (result) {
    thisDoc.querySelector('.input__form').classList.remove('loading');
    thisDoc.querySelector('textarea').disabled = false;

    var resultText;

    if (result.data.gender_label.FF >= result.data.gender_label.MM) {
      resultText = `AI prediction: Conversation between two females (${Math.round(
        result.data.gender_label.FF * 100
      )}%)`;
    } else {
      resultText = `AI prediction: Conversation between two males (${Math.round(
        result.data.gender_label.MM * 100
      )}%)`;
    }

    if (partOfSample >= 0) {
      var exampleText =
        'Truth: ' +
        movieExamples[partOfSample].label +
        ' conversation from ' +
        movieExamples[partOfSample].movie;

      thisDoc.querySelector(
        '.js-test-results-ffmm-movie'
      ).innerHTML = exampleText;
    }

    thisDoc.querySelector('.js-test-results').classList.add('active');
    thisDoc.querySelector('.js-test-results-quote').innerHTML = resultText;
  });
});

document.querySelector('textarea').addEventListener('input', clearResults);

window.onload = function () {
  addRandomText();
  var len = document.querySelector('textarea').value.length;
  document.querySelector('textarea').setSelectionRange(len, len);
};
